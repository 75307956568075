import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'AppointmentType',
          possibleTypes: [{ name: 'DailyAppointmentType' }, { name: 'RecurrentAppointmentType' }],
        },
        {
          kind: 'INTERFACE',
          name: 'ServiceInfoModelsUnion',
          possibleTypes: [
            { name: 'DogWalkingAppointmentInfoType' },
            { name: 'CleaningAppointmentInfoType' },
            { name: 'MassageAppointmentInfoType' },
            { name: 'ChoreAppointmentInfoType' },
          ],
        },
      ],
    },
  },
});

export default fragmentMatcher;
