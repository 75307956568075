import apolloLogger from 'apollo-link-logger';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';

import ENV from '@config';
import { getToken } from '@utils/token';

import ApolloSentryLink from './apolloSentryLink';

import handleApiErrors from './handleApiErrors';
import fragmentMatcher from './fragmentMatcher';

const httpLink = new BatchHttpLink({
  uri: ENV.GRAPHQL_URI,
});

const errorLink = onError(handleApiErrors);

const middlewareLink = setContext(async () => {
  const headers = {};
  const authToken = await getToken();

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return {
    headers,
  };
});

const link = ApolloLink.from([
  middlewareLink,
  errorLink,
  ...(ENV.IS_PROD ? [] : [apolloLogger]),
  ApolloSentryLink,
  httpLink,
]);

export default new ApolloClient({
  link,
  cache: new InMemoryCache({ fragmentMatcher }),
  defaultOptions: {
    query: { errorPolicy: 'all' },
    mutate: { errorPolicy: 'all' },
  },
});
