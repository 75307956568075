import React from 'react';

import { Button, Animated } from '@components';
import { LINKS } from '@src/constants/externalLinks';

const UseCredits = () => {
  return (
    <section className="use-credits section">
      <div className="use-credits__container container section">
        <Animated className="use-credits__content">
          <h2 className="subtitle">
            Reach for your Amenify wallet for everything around your community
          </h2>
          <p className="use-credits__description small-text">
            Utilize your monthly credits on your Amenify App. As a part of our partner community,
            Amenify offers residents $20 each month. Also, you get $50 signing up credit too.
          </p>
          <Button href={LINKS.COMMON} className="button-medium">
            Use your credits now
          </Button>
        </Animated>
      </div>
      <Animated className="use-credits__image-wrapper">
        <img src="/use-credits-image.png" className="use-credits__image" />
      </Animated>
    </section>
  );
};

export default UseCredits;
