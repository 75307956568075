import React from 'react';
import { PropTypes } from 'prop-types';

import { Button, Animated } from '@components';
import { LINKS } from '@src/constants/externalLinks';

import { CleaningBanner, CleaningIcon, BoltIcon } from '@assets';

const PROS_POINTS = ['Background Checks', 'Completed Training', 'Minimum Quality Rating'];

const Cleaning = ({ name }) => {
  if (!name) {
    return null;
  }

  return (
    <Animated>
      <section className="cleaning section" id="cleaning-and-chores">
        <div className="cleaning__header container">
          <h3 className="cleaning__header__title">Cleaning and Chores</h3>
          <p className="cleaning__header__text">
            All onsite pros meet background checks, complete training, and must meet minimum quality
            ratings.
          </p>
        </div>

        <div className="cleaning__body">
          <div className="cleaning__content container">
            <img alt="clean-image" src={CleaningIcon} className="cleaning__content__image" />

            <h3 className="cleaning__content__title">
              Meet your Cleaning Pros {Boolean(name) && `at ${name}`}.
            </h3>

            <p className="cleaning__content__description">
              All onsite pros meet background checks, complete training, and must meet minimum
              quality ratings.
            </p>

            <div className="cleaning__content__points">
              {PROS_POINTS.map((item, key) => (
                <div key={key} className="cleaning__content__points__point">
                  <img src={BoltIcon} alt="bolt-icon" />
                  <p>{item}</p>
                </div>
              ))}
            </div>

            <div className="cleaning__content__buttons">
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href={LINKS.COMMON}
                className="button cleaning__content__buttons__primary">
                Book now
              </Button>
              <Button
                target="_blank"
                rel="noopener noreferrer"
                href={LINKS.SIGNUP}
                className="button cleaning__content__buttons__secondary"
                onClick={open}>
                Create an account
              </Button>
            </div>
          </div>

          <div className="cleaning__image">
            <img src={CleaningBanner} alt="cleaning-banner" />
          </div>
        </div>
      </section>
    </Animated>
  );
};

Cleaning.propTypes = {
  name: PropTypes.string,
};

Cleaning.defaultProps = {
  name: '',
};

export default Cleaning;
