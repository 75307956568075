import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { Logo, Button } from '@components';

import MobileNav from './mobile-nav';
import { getMenuItems } from './constants';
import { LINKS } from '@src/constants/externalLinks';

const Header = ({ name, enabledProductCategories }) => {
  const { pathname } = useLocation();
  const [menuIsOpened, setMenuIsOpened] = useState(false);

  const [isOpened, setIsOpened] = useState(true);

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setIsOpened(false);
  }, []);

  return (
    <header className={`header ${isOpened && 'showBar'}`}>
      {isOpened && !menuIsOpened && (
        <div className="header__announcement">
          <span className="header__announcement__text">
            Residents get a $50 wallet credit for signing up and a monthly $20 credit on the Amenify
            app
          </span>

          <Button
            onClick={() => {
              window.open(LINKS.SIGNUP, '_blank');
            }}
            className="header__announcement__button">
            Sign Up
            <Icon
              name="close"
              onClick={handleClose}
              style={{ color: 'black', marginLeft: '10px' }}
            />
          </Button>
        </div>
      )}
      <div className="header__container container">
        <div className="header__logo-wrapper">
          {Boolean(name) && <span className="header__property">{name} |</span>}
          <Logo className="header__logo" />
        </div>

        <nav className="header__nav">
          <ul className="header__list">
            {getMenuItems(enabledProductCategories).map(({ label, link, items }) => {
              const isActive = pathname.includes(link) || pathname.includes(label.toLowerCase());

              return (
                <li className="header__list-item" key={label}>
                  {Boolean(items) && Boolean(items.length) && (
                    <>
                      <span className={`header__link${isActive ? ' header__link--active' : ''}`}>
                        {label}
                      </span>
                      <ul>
                        {items.map(({ label, link }) => (
                          <li key={link}>
                            <a className="header__link" href={link}>
                              {label}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {!items && (
                    <a
                      className={`header__link ${isActive ? 'header__link--active' : ''}`}
                      href={link}>
                      {label}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="header__buttons-wrapper">
          <Button
            href={LINKS.SIGNIN}
            className="header__button"
            target="_blank"
            rel="noopener noreferrer">
            Login
          </Button>
          <Button
            href={LINKS.SIGNUP}
            className="header__button"
            target="_blank"
            rel="noopener noreferrer">
            Create an account
          </Button>
          <Button
            onClick={() => setMenuIsOpened(!menuIsOpened)}
            className={`header__menu-button${menuIsOpened ? ' header__menu-button--opened' : ''}`}>
            <span />
          </Button>
        </div>
        <MobileNav isOpened={menuIsOpened} enabledProductCategories={enabledProductCategories} />
      </div>
    </header>
  );
};

Header.propTypes = {
  name: PropTypes.string,
  enabledProductCategories: PropTypes.object,
};

Header.defaultProps = {
  name: '',
  enabledProductCategories: {},
};

export default Header;
