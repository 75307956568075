import React from 'react';
import { PropTypes } from 'prop-types';

import { Map, Animated } from '@components';

const Contacts = ({ name, address, enabledProductCategories }) => {
  if (!name) {
    return null;
  }

  return (
    <section className="contacts section">
      <div className="contacts__container container section">
        <div className="contacts__content">
          {Boolean(name) && (
            <>
              <Animated>
                <span className="contacts__property small-text">{name} + Amenify</span>
              </Animated>
              <Animated>
                <span className="contacts__title text">Explore all Amenify services at {name}</span>
              </Animated>
            </>
          )}
          <Animated>
            <ul className="contacts__list">
              {enabledProductCategories.carWashing && (
                <li>
                  <a href="#car-washing-services">Car Washing</a>
                </li>
              )}
              {(enabledProductCategories.cleaning || enabledProductCategories.chores) && (
                <li>
                  {enabledProductCategories.cleaning && <a href="#cleaning-and-chores">Cleaning</a>}
                  {enabledProductCategories.cleaning && enabledProductCategories.chores && (
                    <span> & </span>
                  )}
                  {enabledProductCategories.chores && <a href="#cleaning-and-chores">Chores</a>}
                </li>
              )}
              {enabledProductCategories.meals && (
                <li>
                  <a href="#meal-delivery-services">Meal Delivery</a>
                </li>
              )}
              {enabledProductCategories.mealme && (
                <li>
                  <a href="#food-and-grocery">Food / Grocery</a>
                </li>
              )}
            </ul>
          </Animated>
          <Animated>
            <span className="contacts__label strong">Hours</span>
          </Animated>
          <Animated>
            <span className="small-text">24/7 support</span>
          </Animated>
          <Animated>
            <span className="contacts__label strong">Phone</span>
          </Animated>
          <Animated>
            <span className="small-text">719-767-1963</span>
          </Animated>
          <Animated>
            <span className="contacts__label strong">Email</span>
            <a href="mailto:concierge@amenify.com?page_source=landing-test" className="small-text">
              concierge@amenify.com
            </a>
          </Animated>
        </div>
        <Map address={address} popupText="Test text" className="contacts__map" />
      </div>
    </section>
  );
};

Contacts.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  enabledProductCategories: PropTypes.object,
};

Contacts.defaultProps = {
  name: '',
  address: '',
  enabledProductCategories: {},
};

export default Contacts;
