import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';

import { Accordion, Button, Animated } from '@components';
import { LINKS } from '@src/constants/externalLinks';

const WeeklyMenu = ({ items }) => {
  const [openedDay, setOpenedDay] = useState();

  if (!Object.keys(items).length) {
    return null;
  }

  return (
    <div className="weekly-menu">
      <div className="weekly-menu__container container">
        <Animated>
          <h2 className="subtitle">Stop Wasting Money on Delivery Fees</h2>
        </Animated>
        <Animated>
          <p className="weekly-menu__description">
            Same kitchen, Same Great food but one of them is half the price.
          </p>
        </Animated>
        <div className="weekly-menu__content">
          <Animated className="weekly-menu__image-wrapper">
            <img className="weekly-menu__image" src="/weekly-menu-image.png" />
          </Animated>
          <Animated className="weekly-menu__menu">
            <span className="weekly-menu__subtitle text">Checkout this Week’s Menu</span>
            {Object.keys(items).map((day) => {
              const itemsForDay = items[day];

              return (
                <Accordion
                  key={day}
                  opened={day === openedDay}
                  onClick={() => setOpenedDay(day === openedDay ? undefined : day)}
                  title={moment().startOf('isoWeek').add(day, 'days').format('dddd')}
                  className="weekly-menu__accordion">
                  <div className="weekly-menu__item">
                    {itemsForDay.map(({ title, price }) => (
                      <div className="weekly-menu__info" key={title}>
                        <span>{title}</span>
                        <span>${price}</span>
                      </div>
                    ))}
                  </div>
                </Accordion>
              );
            })}
            <span className="weekly-menu__availability strong">LIMITED AVAILABILITY</span>
            <Button href={LINKS.COMMON} className="button">
              Order now
            </Button>
          </Animated>
        </div>
      </div>
    </div>
  );
};

WeeklyMenu.propTypes = {
  items: PropTypes.object,
};

WeeklyMenu.defaultProps = {
  items: {},
};

export default WeeklyMenu;
