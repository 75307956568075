import React from 'react';
import { PropTypes } from 'prop-types';
import { Animated } from '@components';
import {
  BannerImage,
  BannerServicesImage,
  ResponsiveBannerImage1,
  ResponsiveBannerImage2,
} from '@assets';
import { LINKS } from '@src/constants/externalLinks';

const SplashScreen = ({ name, backgroundImageUrl }) => {
  return (
    <Animated className="splash-screen">
      <div className="splash-screen__container container">
        <div className="splash-screen__container__description">
          <h3 className="splash-screen__container__description__title">
            Access all your home amenities at one place
          </h3>
          {Boolean(name) && (
            <p className="splash-screen__container__description__text">
              35,000+ five star ratings. Locally trained, dedicated lifestyle service teams for you.
              All thanks to your management team at {name}.
            </p>
          )}

          <img
            alt="banner"
            src={BannerServicesImage}
            className="splash-screen__container__description__image"
          />

          <div className="splash-screen__container__description__mobileImage">
            <img alt="banner-mobile" src={ResponsiveBannerImage1} />
            <img alt="banner-mobile" src={ResponsiveBannerImage2} />
          </div>

          <div className="splash-screen__container__description__buttons">
            <a
              className="splash-screen__container__description__buttons__button primary"
              href={LINKS.SIGNUP}
              target="_blank"
              rel="noopener noreferrer">
              Create an account
            </a>
            <a
              className="splash-screen__container__description__buttons__button secondary"
              href="#cleaning-and-chores">
              Explore Amenities
            </a>
          </div>
        </div>
        <div className="splash-screen__container__image">
          {backgroundImageUrl && (
            <div
              className="propertyImage"
              style={{
                backgroundImage: `url(${backgroundImageUrl})`,
              }}></div>
          )}
          <img alt="banner" src={BannerImage} />
        </div>
      </div>
    </Animated>
  );
};

SplashScreen.propTypes = {
  name: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
};

SplashScreen.defaultProps = {
  name: '',
  backgroundImageUrl: '/splash-image.jpeg',
};

export default SplashScreen;
