import { useMemo } from 'react';

import useLandingProperty from './useLandingProperty';

export const groupMealPrices = (mealPrices) =>
  mealPrices.reduce((acc, item) => {
    if (typeof item.day !== 'number') {
      return acc;
    }

    if (acc[item.day]) {
      return {
        ...acc,
        [item.day]: [...acc[item.day], { price: item.price, title: item.title }],
      };
    }

    return { ...acc, [item.day]: [{ price: item.price, title: item.title }] };
  }, {});

const useMealPrices = () => {
  const { loading, error = null, landingProperty } = useLandingProperty();

  const mealPrices = useMemo(
    () => groupMealPrices(landingProperty?.mealPrices || []),
    [landingProperty],
  );

  return { loading, error, mealPrices };
};

export default useMealPrices;
