import React from 'react';
import { PropTypes } from 'prop-types';

const Accordion = ({ title, text, opened, onClick, children, className }) => {
  return (
    <div className={`accordion${opened ? ' accordion--opened' : ''} ${className ? className : ''}`}>
      <button className="accordion__header" onClick={onClick}>
        <span className="accordion__title">{title}</span>
        <img src="./down-arrow.svg" className="accordion__icon" />
      </button>
      {Boolean(children) ? (
        <div className="accordion__content">{children}</div>
      ) : (
        <p className="accordion__content">{text}</p>
      )}
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  opened: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

Accordion.defaultProps = {
  opened: false,
  className: '',
  text: '',
  onClick: () => null,
};

export default Accordion;
