import React, { useState, useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import GoogleMapReact from 'google-map-react';

import Marker from './Marker';

export const GOOGLE_KEY = 'AIzaSyBHgHvEkQ4VT2r1y5P3q5FVH_y_ATs0oBI';
const key = { key: GOOGLE_KEY, libraries: 'places' };

const Map = ({ address, className }) => {
  const [coords, setCoords] = useState({});

  useEffect(() => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        setCoords({ lat, lng });
      }
    });
  }, [address]);

  if (!Object.keys(coords).length) {
    return null;
  }

  return (
    <div className={`map ${className ? className : ''}`}>
      <GoogleMapReact
        options={{
          fullscreenControl: false,
          disableDefaultUI: true,
        }}
        defaultCenter={coords}
        defaultZoom={16}
        bootstrapURLKeys={key}>
        <Marker style={{ width: '50px', height: '50px' }} lat={coords.lat} lng={coords.lng} />
      </GoogleMapReact>
    </div>
  );
};

Map.propTypes = {
  address: PropTypes.string,
  className: PropTypes.string,
};

Map.defaultProps = {
  address: '',
  className: '',
};

export default Map;
