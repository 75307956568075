import * as Sentry from '@sentry/react';

import { API_ERROR_CODES } from '@constants/errors';

const handleApiErrors = ({ operation, networkError, response, graphQLErrors = [], forward }) => {
  graphQLErrors.forEach(({ code, message }) => {
    if ([API_ERROR_CODES.INVALID_INPUT, API_ERROR_CODES.INTERNAL_ERROR].includes(code)) {
      Sentry.captureException(message, {
        extra: {
          info: {
            response,
            graphQLErrors,
            networkError,
            message,
            code,
            operation,
          },
          logger: 'handleApiErrors.graphQLError',
        },
      });
    }

    switch (code) {
      case API_ERROR_CODES.INVALID_INPUT:
        alert(message);
        break;
      case API_ERROR_CODES.INTERNAL_ERROR:
        alert('Looks like something went wrong.');
        break;
      default:
        break;
    }
  });
  if (networkError) {
    alert('Looks like something went wrong.');
    Sentry.captureException(networkError.message, {
      extra: {
        info: { networkError, operation, response },
        logger: 'handleApiErrors.networkError',
      },
    });
  }
};

export default handleApiErrors;
