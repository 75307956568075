import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';

import Animated from '@components/animated';

const Poster = ({ urgeCard, backgroundColor }) => {
  const texts = useMemo(
    () =>
      Array(4)
        .fill('')
        .map((_, i) => (
          <p className="marquee" key={i}>
            <span>
              {urgeCard.bookedToday} neighbors have booked a {urgeCard.purchaseType} •
            </span>
          </p>
        )),
    [urgeCard],
  );

  return (
    <Animated className="poster" style={{ backgroundColor }}>
      <div className="container">
        <div className="poster__content">
          <div>{texts}</div>
        </div>
      </div>
    </Animated>
  );
};

Poster.propTypes = {
  urgeCard: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
};

Poster.defaultProps = {
  backgroundColor: 'white',
};

export default Poster;
