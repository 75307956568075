import { useMemo } from 'react';

import useLandingProperty from './useLandingProperty';

export const groupCarWashingPrices = (carWashPrices) =>
  carWashPrices.reduce((acc, item) => {
    if (!item.duration) {
      return acc;
    }

    return [
      {
        duration: item.duration,
        description: item.durationShortDescription,
        price: item.price,
      },
    ];
  }, []);

const useCarWashingPrices = () => {
  const { loading, error = null, landingProperty } = useLandingProperty();

  const carWashingPrices = useMemo(
    () => groupCarWashingPrices(landingProperty?.carWashingPrices || []),
    [landingProperty],
  );

  return { loading, error, carWashingPrices };
};

export default useCarWashingPrices;
