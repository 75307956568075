import React from 'react';
import { PropTypes } from 'prop-types';

const Button = ({ onClick, className, textClassName, children, href, target, ...rest }) => {
  return href ? (
    <a
      href={href}
      target={target}
      className={`button-small ${className ? className : ''}`}
      {...rest}>
      <span className="button-small__text">{children}</span>
    </a>
  ) : (
    <button className={`button-small ${className ? className : ''}`} onClick={onClick}>
      <span className={`button-small__text ${textClassName ? textClassName : ''}`}>{children}</span>
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

Button.defaultProps = {
  onClick: () => null,
  className: '',
  textClassName: '',
  href: '',
  target: '',
};

export default Button;
