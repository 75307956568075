export const SERVICE_ITEMS = [
  {
    title: 'Trusted Pros',
    description:
      'All onsite pros have background checks, complete training, and must meet minimum quality ratings.',
  },
  {
    title: 'Concierge Support',
    description:
      'Stuck at home and/or working late? We have seen it all. Our team is ready via text or call to help with whatever you need.',
  },
  {
    title: 'Simplified Living',
    description:
      'We take care of scheduling, key releases, real-time notifications, and we will follow up to make sure it met your expectations',
  },
  {
    title: 'Save Time and Money',
    description:
      'We hire for your community and remove inefficiencies. Therefore, we provide high-quality services with special pricing and monthly credits.',
  },
];
