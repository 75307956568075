import React from 'react';

import { Button, Animated } from '@components';
import { LINKS } from '@src/constants/externalLinks';

const MealDelivery = () => {
  return (
    <section id="meal-delivery-services" className="meal-delivery section">
      <div className="meal-delivery__container container">
        <div className="meal-delivery__content">
          <Animated>
            <h2 className="title">Meal Delivery</h2>
          </Animated>

          <Animated>
            <p className="text">
              Chef-prepared delicious meals handcrafted and delivered to your lobby.
            </p>
          </Animated>
          <Animated>
            <span className="strong">LIMITED AVAILABILITY</span>
          </Animated>

          <Animated>
            <Button href={LINKS.COMMON} className="meal-delivery__button button">
              Order now
            </Button>
          </Animated>
          <Animated>
            <Button href={LINKS.SIGNUP} className="button">
              Create an account
            </Button>
          </Animated>
        </div>
        <Animated className="meal-delivery__image-wrapper">
          <img src="/meal-delivery.png" className="meal-delivery__image" />
        </Animated>
      </div>
    </section>
  );
};

export default MealDelivery;
