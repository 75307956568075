import React from 'react';

import { Button, Animated } from '@components';
import { LINKS } from '@src/constants/externalLinks';

const ShareReferral = () => {
  return (
    <section className="share-referral section">
      <div className="share-referral__container container section">
        <Animated className="share-referral__image-wrapper">
          <img src="/share-referral-image.png" className="share-referral__image" />
        </Animated>
        <Animated className="share-referral__content">
          <h2 className="share-referral__title subtitle">
            Know a friend who would love a refreshing home clean?
          </h2>
          <p className="share-referral__description small-text">
            Join our referral program and share it with your friends and neighbors to score some
            special bonuses. Earn $25 off on your next home cleaning or chore when someone signs up
            using your referral link.
          </p>
          <Button href={LINKS.COMMON} className="button-medium">
            Share your Referral Code
          </Button>
        </Animated>
      </div>
    </section>
  );
};

export default ShareReferral;
