import React from 'react';
import { PropTypes } from 'prop-types';

const Checkbox = ({ label, checked, required, onChange }) => {
  const labelComponent = typeof label === 'function' ? label() : label;

  return (
    <label className="checkbox" onMouseUp={() => onChange(!checked)}>
      <input
        type="checkbox"
        className="checkbox__input"
        checked={checked}
        required={required}
        onChange={() => null}
      />
      <span className="checkbox__fake-checkbox" />
      {!!labelComponent && <span className="checkbox__label">{labelComponent}</span>}
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  checked: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  label: '',
  checked: false,
  required: false,
};

export default Checkbox;
