import React from 'react';

import {
  useLandingProperty,
  useCleaningPrices,
  useMealPrices,
  useUrgeCards,
  useCarWashingPrices,
} from '@hooks/queries';

import {
  // AnnouncementBar,
  Header,
  SplashScreen,
  Services,
  Cleaning,
  FoodAndGrocery,
  Prices,
  Quote,
  MealDelivery,
  DeliveryFeatures,
  WeeklyMenu,
  CarWashing,
  CarWashingCategories,
  ShareReferral,
  UseCredits,
  Contacts,
  Poster,
  Footer,
  Handyman,
} from '@components';

import { FoodDeliveryQuote } from '@assets';
const App = () => {
  const { cleaningPrices } = useCleaningPrices();
  const { urgeCards } = useUrgeCards();
  const { mealPrices } = useMealPrices();
  const { carWashingPrices } = useCarWashingPrices();
  const {
    landingProperty: { name, address, backgroundImageUrl },
    enabledProductCategories,
  } = useLandingProperty();

  return (
    <div className="app">
      <div className="app-container">
        {/* <AnnouncementBar /> */}
        <Header name={name} enabledProductCategories={enabledProductCategories} />
        <SplashScreen name={name} backgroundImageUrl={backgroundImageUrl} />
        <Services name={name} />
        {enabledProductCategories.cleaning && <Cleaning name={name} />}

        {Boolean(urgeCards.length) && urgeCards[0] && enabledProductCategories.cleaning && (
          <Poster urgeCard={urgeCards[0]} backgroundColor="#f8f8f8" />
        )}

        {enabledProductCategories.cleaning && (
          <>
            <Prices name={name} cleaningPrices={cleaningPrices} />
            <Quote
              image="/quote-image.png"
              icon="/quote.png"
              text="“The cleaning was unbeatable and I’m very happy with the experience. At this price, I can
              buy twice as many cleanings!”"
              author="Jamie H."
            />
          </>
        )}

        {enabledProductCategories.mealme && (
          <>
            <FoodAndGrocery />
            <Quote
              image={FoodDeliveryQuote}
              icon="/quote2.png"
              text="“I absolutely love the convenience. The food was cooked fresh and delivered right to the lobby”"
              author="Martin D, Denver Colorado"
            />
          </>
        )}
        {enabledProductCategories.handyman && <Handyman />}

        {enabledProductCategories.carWashing && <CarWashing />}
        {Boolean(urgeCards.length) && urgeCards[1] && enabledProductCategories.carWashing && (
          <Poster urgeCard={urgeCards[1]} />
        )}
        {enabledProductCategories.carWashing && (
          <>
            <CarWashingCategories name={name} carWashingPrices={carWashingPrices} />
            <Quote
              image="/quote-image3.png"
              icon="/quote3.png"
              text="“Great service. Never had an issue and love coming back to a clean car without needing to do any extra work. The fact that you don't need to leave your keys with them made me choose the service.”"
              author="Darrius M."
              fontSize="calc((2 - 1) * 1.2vw + 1rem)"
            />
          </>
        )}

        {enabledProductCategories.meals && <MealDelivery />}

        {enabledProductCategories.meals && (
          <>
            <DeliveryFeatures />
            <WeeklyMenu items={mealPrices} />
            <Quote
              image="/quote-image2.png"
              icon="/quote2.png"
              text="“I absolutely love the convenience. The food was cooked fresh and delivered right to the lobby”"
              author="Martin D."
              light
            />
          </>
        )}

        <Contacts
          name={name}
          address={address}
          enabledProductCategories={enabledProductCategories}
        />
        <ShareReferral />
        <UseCredits />

        <Footer enabledProductCategories={enabledProductCategories} />
        {window['send_to_webengage_page_loaded']({ name }.name)}
      </div>
    </div>
  );
};

export default App;
