import { useMemo } from 'react';

import useLandingProperty from './useLandingProperty';

export const groupCleaningPrices = (cleaningPrices) =>
  cleaningPrices.reduce((acc, item) => {
    if (!item.duration) {
      return acc;
    }

    if (acc[item.duration]) {
      const description = acc[item.duration].description;

      return {
        ...acc,
        [item.duration]: {
          description: description ? description : item.durationShortDescription,
          items: [...acc[item.duration].items, item],
        },
      };
    }

    return {
      ...acc,
      [item.duration]: {
        description: item.description,
        items: [item],
      },
    };
  }, {});

const useCleaningPrices = () => {
  const { loading, error = null, landingProperty } = useLandingProperty();

  const cleaningPrices = useMemo(
    () => groupCleaningPrices(landingProperty?.cleaningPrices || []),
    [landingProperty],
  );

  return { loading, error, cleaningPrices };
};

export default useCleaningPrices;
