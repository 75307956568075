export const getMenuItems = (enabledProductCategories) => {
  const serviceItems = [];
  if (enabledProductCategories.cleaning || enabledProductCategories.chores) {
    serviceItems.push({
      label: 'Cleaning and Chores',
      link: '#cleaning-and-chores',
    });
  }
  if (enabledProductCategories.carWashing) {
    serviceItems.push({
      label: 'Car Washing',
      link: '#car-washing-services',
    });
  }
  if (enabledProductCategories.dogWalking) {
    serviceItems.push({
      label: 'Dog Walking',
      link: 'https://www.amenify.com/dog-walking-services',
    });
  }
  if (enabledProductCategories.meals) {
    serviceItems.push({
      label: 'Meal Delivery',
      link: '#meal-delivery-services',
    });
  }
  if (enabledProductCategories.mealme) {
    serviceItems.push({
      label: 'Food & Grocery',
      link: '#food-and-grocery',
    });
  }
  if (enabledProductCategories.handyman) {
    serviceItems.push({
      label: 'Handyman',
      link: '#handyman',
    });
  }

  return [
    {
      label: 'Overview',
      link: '#overview',
    },
    ...serviceItems,
  ];
};

/*
export const getMenuItems = (enabledProductCategories) => {
  const serviceItems = [];
  if (enabledProductCategories.cleaning) {
    serviceItems.push({
      label: 'Cleaning',
      link: '#cleaning-and-chores',
    });
  }
  if (enabledProductCategories.chores) {
    serviceItems.push({
      label: 'Chores',
      link: '#cleaning-and-chores',
    });
  }
  if (enabledProductCategories.carWashing) {
    serviceItems.push({
      label: 'Car Washing',
      link: '#car-washing-services',
    });
  }
  if (enabledProductCategories.dogWalking) {
    serviceItems.push({
      label: 'Dog Walking',
      link: 'https://www.amenify.com/dog-walking-services',
    });
  }
  if (enabledProductCategories.meals) {
    serviceItems.push({
      label: 'Meal Delivery',
      link: '#meal-delivery-services',
    });
  }

  return [
    {
      label: 'Residents',
      items: [
        {
          label: 'Residents',
          link: 'https://www.amenify.com/residents-services',
        },
        {
          label: 'Resident Ambassador',
          link: 'https://www.amenify.com/resident-ambassador',
        },
      ],
    },
    {
      label: 'Property Managers',
      link: 'https://www.amenify.com/managers',
    },
    {
      label: 'Providers',
      link: 'https://www.amenify.com/providers',
    },
    ...(serviceItems.length
      ? [
          {
            label: 'Services',
            items: serviceItems,
          },
        ]
      : []),
    {
      label: 'How it works',
      link: 'https://www.amenify.com/how-it-works',
    },
    {
      label: 'About',
      link: '#about',
    },
  ];
};
 */
