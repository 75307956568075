import React from 'react';
import { PropTypes } from 'prop-types';

import { Button, Animated } from '@components';
import { LINKS } from '@src/constants/externalLinks';

const CarWashingCategories = ({ name, carWashingPrices }) => {
  if (!name) {
    return null;
  }

  return (
    <div className="cwc section">
      <div className="cwc__container container">
        <Animated className="cwc__head">
          <h2 className="subtitle">Car Washing Categories and Pricing for {name}.</h2>
          <p className="cwc__description small-text">
            Know when your dedicated service teams visit the {name} every week and enjoy special
            resident pricing.
          </p>
        </Animated>
        <Animated className="cwc__content">
          {carWashingPrices.map(({ duration, description, price }, index, arr) => (
            <div
              className="cwc__price-wrapper"
              key={index}
              style={{ flexBasis: `${100 / arr.length}%` }}>
              <span className="cwc__subtitle strong">{duration}</span>
              <span className="cwc__text">{description}</span>
              <span className="cwc__price text">${price}</span>
            </div>
          ))}
        </Animated>
        <Animated>
          <strong className="cwc__availability strong">LIMITED AVAILABILITY</strong>
          <Button href={LINKS.COMMON} className="cwc__button button-medium">
            Secure your spot
          </Button>
        </Animated>
      </div>
    </div>
  );
};

CarWashingCategories.propTypes = {
  name: PropTypes.string,
  carWashingPrices: PropTypes.array,
};

CarWashingCategories.defaultProps = {
  name: '',
  carWashingPrices: [],
};

export default CarWashingCategories;
