import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';

const Input = ({
  type,
  name,
  label,
  value,
  isRequired,
  onChange,
  onBlur,
  pattern,
  style,
  minLength,
  error,
  errorText,
  tooltip,
}) => {
  const className = useMemo(() => {
    let name = 'input';

    if (error) {
      name += ' input--error';
    }

    if (!error && tooltip) {
      name += ' input--tooltip';
    }

    return name;
  }, [tooltip, error]);

  return (
    <label className={className} style={style}>
      {!error && tooltip && <span className="tooltip-text">{tooltip}</span>}
      <input
        type={type}
        name={name}
        className={`input__text-input ${value ? 'input__text-input--active' : ''}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        required={isRequired}
        pattern={pattern}
        minLength={minLength}
      />
      <div className="input__line" />
      {!!label && (
        <span className="input__label">
          {label}
          {isRequired && ' *'}
        </span>
      )}
      {errorText && <span className="error-text">{errorText}</span>}
    </label>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  pattern: PropTypes.string,
  style: PropTypes.object,
  minLength: PropTypes.string,
  error: PropTypes.bool,
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorText: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  label: '',
  value: '',
  isRequired: false,
  style: {},
  onBlur: () => null,
  error: false,
  tooltip: false,
  errorText: '',
};

export default Input;
