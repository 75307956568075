import React from 'react';

import { Button, Animated } from '@components';
import { LINKS } from '@src/constants/externalLinks';

const CarWashing = () => {
  return (
    <section id="car-washing-services" className="car-washing section">
      <div className="car-washing__container container">
        <div className="car-washing__content">
          <Animated>
            <h2 className="title">Car Washing</h2>
          </Animated>
          <Animated>
            <span className="car-washing__subtitle subtitle">
              Expert cleaning right at your parking spot.
            </span>
          </Animated>
          <Animated>
            <span className="car-washing__list-title text">All Amenify Car washing partners:</span>
          </Animated>
          <Animated>
            <ul className="car-washing__list">
              <li className="car-washing__list-item">
                <span className="small-text">Insured service</span>
              </li>
              <li className="car-washing__list-item">
                <span className="small-text">Our service is 100% contactless</span>
              </li>
              <li className="car-washing__list-item">
                <span className="small-text">
                  Ensure washes are waterless, with no drips or mess
                </span>
              </li>
            </ul>
            <strong className="strong">LIMITED AVAILABILITY</strong>
            <div className="car-washing__button-wrapper">
              <Button href={LINKS.COMMON} className="car-washing__button button">
                Secure your spot
              </Button>
              <br />
              <Button href={LINKS.SIGNUP} className="car-washing__button button">
                Create an account
              </Button>
            </div>
          </Animated>
        </div>
      </div>
      <Animated className="car-washing__image-wrapper">
        <img src="/car-washing-image.png" className="car-washing__image" />
      </Animated>
    </section>
  );
};

export default CarWashing;
