import React from 'react';
import { PropTypes } from 'prop-types';

const Logo = ({ color, className }) => (
  <svg
    width="84"
    height="22"
    viewBox="0 0 84 22"
    className={className}
    fill={color}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1903 14.0225V8.54014C11.1903 6.02308 9.26633 4.51953 6.04483 4.51953C3.18808 4.51953 1.21119 5.86716 0.623695 8.21994C0.58193 8.39257 0.618127 8.57633 0.729501 8.71555C0.838091 8.85477 1.00794 8.9383 1.18613 8.9383H2.04093C2.29431 8.9383 2.51706 8.77402 2.59223 8.53178C3.12404 6.8389 4.76403 6.48528 6.04483 6.48528C8.13867 6.48528 9.1995 7.1758 9.1995 8.54014C9.1995 9.38658 7.9911 9.65388 5.45176 9.8989C3.82013 10.0465 0 10.3945 0 13.939C0 15.7767 1.49798 17.7563 4.7863 17.7563C8.11639 17.7563 10.7727 16.3809 11.1903 14.0225ZM4.78352 15.7683C4.75568 15.7683 2.05485 15.746 2.05485 13.9167C2.05485 13.0703 2.33329 12.1431 5.6411 11.8173C6.84116 11.7171 8.16094 11.6057 9.1995 11.1546V12.7696C9.1995 14.8411 6.98316 15.7683 4.78352 15.7683Z" />
    <path d="M39.151 4.51953C35.6427 4.51953 32.7916 7.52385 32.7916 11.2159C32.7916 14.9219 35.6455 17.8232 39.2875 17.8232C42.2305 17.8232 44.3439 16.3057 45.2376 13.5548C45.2933 13.3793 45.2627 13.1872 45.1541 13.0369C45.0455 12.8865 44.8701 12.7974 44.6863 12.7974H43.8093C43.567 12.7974 43.3498 12.9478 43.2663 13.1761C42.6175 14.9386 41.2783 15.8351 39.2875 15.8351C36.9291 15.8351 35.0942 14.1172 34.8186 11.7505H44.9341C45.2488 11.7505 45.5077 11.4971 45.5133 11.1797C45.5383 9.45619 44.8033 7.66585 43.542 6.38505C42.353 5.18221 40.7938 4.51953 39.151 4.51953ZM35.0107 9.76247C35.5759 7.85519 37.227 6.53262 39.1482 6.53262C41.0611 6.53262 42.7206 7.85519 43.3025 9.76247H35.0107Z" />
    <path d="M62.439 1.54932H61.6065C61.2863 1.54932 61.0273 1.80826 61.0273 2.12846V3.04452C61.0273 3.36472 61.2863 3.62366 61.6065 3.62366H62.439C62.7592 3.62366 63.0182 3.36472 63.0182 3.04452V2.12846C63.0182 1.80826 62.7592 1.54932 62.439 1.54932Z" />
    <path d="M62.439 4.81177H61.6065C61.2863 4.81177 61.0273 5.07071 61.0273 5.39091V16.9766C61.0273 17.2968 61.2863 17.5558 61.6065 17.5558H62.439C62.7592 17.5558 63.0182 17.2968 63.0182 16.9766V5.39091C63.0182 5.07071 62.7592 4.81177 62.439 4.81177Z" />
    <path d="M71.9481 0.0222753C71.7783 0.00835357 70.7509 0 70.5894 0C68.103 0 66.4992 1.79869 66.4992 4.58026V4.81136H65.5024C65.1822 4.81136 64.9232 5.07031 64.9232 5.39051V6.22303C64.9232 6.54323 65.1822 6.80218 65.5024 6.80218H66.4992V16.9762C66.4992 17.2964 66.7581 17.5553 67.0783 17.5553H67.9331C68.2533 17.5553 68.5123 17.2964 68.5123 16.9762V6.80218H71.0377C71.3579 6.80218 71.6168 6.54323 71.6168 6.22303V5.39051C71.6168 5.07031 71.3579 4.81136 71.0377 4.81136H68.5123V4.64709C68.5123 1.93234 70.194 1.93234 70.7453 1.93234C70.8288 1.93234 71.7755 1.93513 71.8646 1.94069C71.8758 1.94069 71.8897 1.94069 71.9008 1.94069C72.0484 1.94069 72.1904 1.88501 72.2962 1.78477C72.4131 1.67618 72.4772 1.52304 72.4772 1.36433V0.598636C72.4744 0.297926 72.2461 0.0501189 71.9481 0.0222753Z" />
    <path d="M83.8975 5.06236C83.7889 4.90643 83.6107 4.81177 83.4213 4.81177H82.5443C82.3048 4.81177 82.0876 4.95934 82.0041 5.18487L78.61 14.1421L75.2576 5.18765C75.1741 4.96212 74.9569 4.81177 74.7147 4.81177H73.8153C73.626 4.81177 73.4478 4.90643 73.3392 5.06236C73.2306 5.21828 73.2056 5.41876 73.2752 5.59696L77.5631 16.9543L77.1009 18.1711C76.6164 19.4658 75.9064 20.0171 74.7286 20.0171C74.5114 20.0171 74.272 19.9976 74.0186 19.9586C73.9907 19.9531 73.9601 19.9531 73.9323 19.9531C73.7958 19.9531 73.6594 20.0032 73.5536 20.0923C73.4255 20.2009 73.3531 20.3624 73.3531 20.5294V21.3174C73.3531 21.5959 73.5508 21.8353 73.8265 21.8854C74.1801 21.9522 74.5281 21.9857 74.8567 21.9857C76.7528 21.9857 78.2146 20.8775 78.972 18.8672L83.9671 5.59417C84.0283 5.41597 84.0033 5.21828 83.8975 5.06236Z" />
    <path d="M26.1448 4.51953C24.5159 4.51953 23.0987 5.23789 22.2327 6.44909C22.2272 6.45744 22.2216 6.46301 22.216 6.47136C22.2105 6.46301 22.2049 6.45744 22.1993 6.44909C21.3334 5.23789 19.9189 4.51953 18.2873 4.51953C15.3972 4.51953 13.3005 6.61615 13.3005 9.50631V16.9739C13.3005 17.2941 13.5595 17.5531 13.8797 17.5531H14.7122C15.0324 17.5531 15.2914 17.2941 15.2914 16.9739V9.50909C15.2914 7.75773 16.5248 6.53262 18.2901 6.53262C20.0415 6.53262 21.2666 7.75773 21.2666 9.50909V16.9767C21.2666 17.2969 21.5255 17.5559 21.8457 17.5559H22.1771H22.2606H22.5919C22.9121 17.5559 23.1711 17.2969 23.1711 16.9767V9.50909C23.1711 7.75773 24.3962 6.53262 26.1475 6.53262C27.9128 6.53262 29.1463 7.75773 29.1463 9.50909V16.9767C29.1463 17.2969 29.4052 17.5559 29.7254 17.5559H30.558C30.8782 17.5559 31.1371 17.2969 31.1371 16.9767V9.50909C31.1343 6.61893 29.0349 4.51953 26.1448 4.51953Z" />
    <path d="M53.8077 4.59749C53.7632 4.58914 53.7159 4.58357 53.6713 4.578C53.6574 4.57522 53.6463 4.57522 53.6323 4.57243C53.3706 4.53902 53.1005 4.51953 52.8277 4.51953C52.8165 4.51953 52.8082 4.51953 52.797 4.51953C52.7859 4.51953 52.7775 4.51953 52.7664 4.51953C52.4907 4.51953 52.2234 4.53902 51.9617 4.57243C51.9478 4.57522 51.9367 4.57522 51.9227 4.578C51.8782 4.58357 51.8309 4.59192 51.7863 4.59749C49.1328 5.00122 47.5262 6.98647 47.5262 9.97965V16.9767C47.5262 17.2969 47.7852 17.5559 48.1054 17.5559H48.9379C49.2581 17.5559 49.5171 17.2969 49.5171 16.9767V9.97965C49.5171 8.9021 49.7844 7.68256 50.8118 7.00596C51.1515 6.79992 51.5329 6.64956 51.9478 6.56603C52.204 6.51591 52.488 6.49085 52.7998 6.48807C53.1117 6.49085 53.3957 6.5187 53.6518 6.56603C54.0639 6.64956 54.4454 6.8027 54.7878 7.00596C55.8153 7.67977 56.0826 8.89932 56.0826 9.97965V16.9767C56.0826 17.2969 56.3415 17.5559 56.6617 17.5559H57.4942C57.8144 17.5559 58.0734 17.2969 58.0734 16.9767V9.97965C58.0678 6.98647 56.4612 5.00401 53.8077 4.59749Z" />
  </svg>
);

Logo.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

Logo.defaultProps = {
  color: '#00121A',
  className: '',
};

export default Logo;
