export const API_ERROR_CODES = {
  ACCESS_DENIED: 'access_denied',
  INVALID_INPUT: 'invalid_input',
  VALIDATION_ERROR: 'validation_error',
  INTERNAL_ERROR: 'internal_error',
  TIMEOUT: 'timeout',
};

export const API_ERROR_MESSAGES = {
  INVALID_INPUT: 'Oops! Looks like something went wrong. ',
  TIMEOUT: 'Connection timed out',
  TIMEOUT_SERVER_MESSAGE: 'Failed to fetch',
  HAS_ACTIVE_SUBSCRIPTION: 'Resident has the active subscription.',
};
