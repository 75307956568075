import React from 'react';
import { PropTypes } from 'prop-types';

import { Animated } from '@components';

import { SERVICE_ITEMS } from './mock';

const Services = ({ name }) => {
  return (
    <section id="overview" className="services section">
      <div className="services__container container">
        {Boolean(name) && <h2 className="services__title title">{name} is Amenified.</h2>}

        <div className="services__content">
          <Animated className="services__image-wrapper" animateIn="fadeInLeft" offset={10}>
            <img src="/services-image.png" className="services__image" />
          </Animated>

          <Animated className="services__list" animateIn="fadeInRight">
            {SERVICE_ITEMS.map(({ title, description }) => (
              <div className="services__list-item" key={title}>
                <span className="services__service-title">{title}</span>
                <p className="services__service-description">{description}</p>
              </div>
            ))}
          </Animated>
        </div>
      </div>
    </section>
  );
};

Services.propTypes = {
  name: PropTypes.string,
};

Services.defaultProps = {
  name: '',
};

export default Services;
