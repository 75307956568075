import React from 'react';
import { PropTypes } from 'prop-types';
import InputMask from 'react-input-mask';

const PhoneInput = ({
  label,
  name,
  value,
  isRequired,
  onChange,
  onBlur,
  style,
  error,
  errorText,
}) => {
  return (
    <label className={`input ${error ? 'input--error' : ''}`} style={style}>
      <InputMask
        mask="+1 (999) 999-9999"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        pattern="\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}"
        type="tel"
        className={`input__text-input ${value ? 'input__text-input--active' : ''}`}
        required={isRequired}
      />
      <div className="input__line" />
      {!!label && (
        <span className="input__label">
          {label}
          {isRequired && ' *'}
        </span>
      )}
      {errorText && <span className="error-text">{errorText}</span>}
    </label>
  );
};

PhoneInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  style: PropTypes.object,
  error: PropTypes.bool,
  errorText: PropTypes.string,
};

PhoneInput.defaultProps = {
  label: '',
  value: '',
  isRequired: false,
  onBlur: () => null,
  style: {},
  error: false,
  errorText: '',
};

export default PhoneInput;
