import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import UrlPattern from 'url-pattern';
import { convertGraphqlErrorToString } from '@utils/error';

import LandingProperty from '@api/queries/LandingProperty.graphql';

const getProductCategories = (categories) =>
  categories.reduce(
    (acc, category) => {
      if (category.includes('meals')) {
        return {
          ...acc,
          meals: true,
        };
      }
      if (category.includes('cleaning')) {
        return {
          ...acc,
          cleaning: true,
        };
      }
      if (category.includes('chore')) {
        return {
          ...acc,
          chores: true,
        };
      }
      if (category.includes('car_washing')) {
        return {
          ...acc,
          carWashing: true,
        };
      }
      if (category.includes('dog_walking')) {
        return {
          ...acc,
          dogWalking: true,
        };
      }
      if (category.includes('mealme')) {
        return {
          ...acc,
          mealme: true,
        };
      }
      if (category.includes('handyman')) {
        return {
          ...acc,
          handyman: true,
        };
      }
      return acc;
    },
    {
      meals: false,
      cleaning: false,
      chores: false,
      carWashing: false,
      dogWalking: false,
      mealme: false,
      handyman: false,
    },
  );

const useLandingProperty = () => {
  const [getLandingProperty, { loading, error = null, data }] = useLazyQuery(LandingProperty);

  useEffect(() => {
    const pattern = new UrlPattern('(/:landingSlug)');
    window.UrlPattern = UrlPattern;
    const path = document.location.pathname;
    const parsed = pattern.match(path);
    const lastLink = path.slice(path.lastIndexOf('/') + 1);

    if (!parsed && lastLink === 'property-landing') {
      window.location.pathname = '/other';
      return;
    }

    const landingSlug = parsed?.landingSlug || lastLink;

    if (landingSlug) {
      getLandingProperty({ variables: { landingSlug } });
    } else {
      window.location.pathname = '/other';
    }
  }, [getLandingProperty]);

  useEffect(() => {
    if (error) {
      const graphql_error = convertGraphqlErrorToString(error);

      if (graphql_error === 'Property not found') {
        window.location.pathname = '/other';
      }
    }
  }, [error]);

  const landingProperty = useMemo(() => data?.landingProperty || {}, [data]);
  const enabledProductCategories = useMemo(
    () => getProductCategories(landingProperty.enabledProductCategories || []),
    [landingProperty?.enabledProductCategories],
  );

  return { loading, error, landingProperty, enabledProductCategories };
};

export default useLandingProperty;
