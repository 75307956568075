import get from 'lodash/get';

export const convertGraphqlErrorsToString = (error) => {
  if (error.graphQLErrors && !error.graphQLErrors.length) return error.toString();
  const errors = get(error, 'graphQLErrors[0].errors', error.graphQLErrors);

  return errors.map((err) => err.message).join('\n');
};

export const convertGraphqlErrorToString = (error) => {
  if (error.graphQLErrors && !error.graphQLErrors.length) return error.toString();
  const errorMessage = get(error, 'graphQLErrors[0].message', error);

  return errorMessage;
};
