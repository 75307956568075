import React from 'react';
import { PropTypes } from 'prop-types';

import Animated from '@components/animated';
import Button from '@components/button';
import { LINKS } from '@src/constants/externalLinks';

const Quote = ({ image, icon, text, author, fontSize, limited, onClick, light }) => {
  return (
    <div className={`quote${light ? ' quote--light' : ''}`}>
      <img className="quote__image" src={image} />
      <div className="quote__container container section">
        {Boolean(icon) && (
          <Animated>
            <img src={icon} className="quote__icon" />
          </Animated>
        )}
        <Animated>
          <p className="quote__text" style={{ fontSize }}>
            {text}
          </p>
          <span className="quote__author">— {author}</span>
        </Animated>
        {limited && (
          <Animated>
            <span className="strong">LIMITED AVAILABILITY</span>
          </Animated>
        )}
        {Boolean(onClick) && (
          <Animated>
            <Button href={LINKS.COMMON}>Secure your spot</Button>
          </Animated>
        )}
      </div>
    </div>
  );
};

Quote.propTypes = {
  image: PropTypes.string.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  limited: PropTypes.bool,
  onClick: PropTypes.func,
  light: PropTypes.bool,
};

Quote.defaultProps = {
  icon: '',
  fontSize: 'calc((3 - 1) * 1.2vw + 1rem)',
  limited: false,
  light: false,
};

export default Quote;
