import React from 'react';
import {
  FoodDeliveryImage,
  FoodDeliveryService1,
  FoodDeliveryService2,
  FoodDeliveryService3,
} from '@assets';
import './FoodAndGrocery.scss';
import { Animated } from '@components';
import { LINKS } from '@src/constants/externalLinks';
import { Icon } from 'semantic-ui-react';

const GROCERY_POINTS = [
  'Get $50 sign-up + $20 monthly wallet credits',
  'Lowest fees through our aggregator model',
  'Choose from 900K+ restaurants, 24K+ groceries across 780+ cities',
];

// const SERVICE_POINTS = [
//   {
//     image: FoodDeliveryService1,
//     title: 'Made fresh daily',
//     description:
//       'The private menu is curated by Amenify, based on your feedback, and all meals are made day-of by local, professional chefs.',
//   },
//   {
//     image: FoodDeliveryService2,
//     title: 'Stop wasting on fees',
//     description:
//       'Other platforms embed $8-12 in fees that don’t make sense -- but not us. We have lower prices and better quality.',
//   },
//   {
//     image: FoodDeliveryService3,
//     title: 'Schedule Meals ',
//     description:
//       'All orders are delivered to a specific location in your building, for a safe and secure pick-up.',
//   },
// ];

const FoodAndGrocery = () => {
  return (
    <section className="food-and-grocery section" id="food-and-grocery">
      <div className="container">
        <Animated animateIn="fadeInLeft">
          <div className="food-and-grocery__upper-section">
            <div className="food-and-grocery__upper-section__text">
              <div className="food-and-grocery__upper-section__text__tags">
                <div className="food-and-grocery__upper-section__text__tags__primary">
                  DELIVERY/PICK-UP
                </div>
                <div className="food-and-grocery__upper-section__text__tags__secondary">NEW</div>
              </div>
              <h3 className="food-and-grocery__upper-section__text__title">Food & Grocery</h3>
              <p className="food-and-grocery__upper-section__text__description">
                Resident’s favourite Amenity of all time
              </p>

              <div className="food-and-grocery__upper-section__text__points">
                {GROCERY_POINTS.map((point, index) => (
                  <div key={index} className="food-and-grocery__upper-section__text__points__point">
                    <Icon name="circle" size="mini" />
                    <p className="pointText">{point}</p>
                  </div>
                ))}
              </div>

              <div className="food-and-grocery__upper-section__text__buttons">
                <a
                  className="food-and-grocery__upper-section__text__buttons__button primary"
                  href={LINKS.SIGNIN}
                  rel="noopener noreferrer"
                  target="_blank">
                  Order Now
                </a>
                <a
                  className="food-and-grocery__upper-section__text__buttons__button secondary"
                  href={LINKS.SIGNUP}
                  rel="noopener noreferrer"
                  target="_blank">
                  Create an account
                </a>
              </div>
            </div>

            <div className="food-and-grocery__upper-section__image">
              <img src={FoodDeliveryImage} alt="food-delivery" />
            </div>
          </div>
        </Animated>

        {/* removing this section - AMENIFY-10367 */}
        {/* <Animated animateIn="fadeInRight">
          <div className="food-and-grocery__lower-section">
            <h3 className="food-and-grocery__lower-section__title">
              Exclusive Meal Delivery Service
            </h3>
            <p className="food-and-grocery__lower-section__description">
              Know when your trusted pros visit the Community every week and enjoy special
              membership pricing.
            </p>

            <div className="food-and-grocery__lower-section__items">
              {SERVICE_POINTS.map((item, index) => (
                <div className="food-and-grocery__lower-section__items__item" key={index}>
                  <img src={item.image} alt="service-point" />
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </Animated> */}
      </div>
    </section>
  );
};

export default FoodAndGrocery;
