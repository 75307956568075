import React from 'react';
import { PropTypes } from 'prop-types';

import { Animated } from '@components';
import { Table } from 'semantic-ui-react';

const Prices = ({ name, cleaningPrices }) => {
  if (!name || !Object.keys(cleaningPrices).length) {
    return null;
  }
  return (
    <section className="prices section">
      <div className="prices__container container">
        <Animated>
          <h2 className="prices__title">Cleaning Categories and Pricing for {name}.</h2>
        </Animated>
        <h3 className="prices__description small-text">
          Know when your dedicated service teams visit the {name} every week and enjoy special
          resident pricing.
        </h3>

        <Animated animateIn="fadeInLeft">
          <div style={{ overflowX: 'auto' }}>
            <Table celled unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="tableHeaders">Category</Table.HeaderCell>
                  <Table.HeaderCell className="tableHeaders">Description</Table.HeaderCell>
                  <Table.HeaderCell className="tableHeaders"># of bathrooms</Table.HeaderCell>
                  <Table.HeaderCell className="tableHeaders">Price</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {Object.keys(cleaningPrices).map((key, index) => {
                  const { description, items } = cleaningPrices[key];
                  return (
                    <>
                      <Table.Row key={index}>
                        <Table.Cell rowSpan={items?.length} className="tableTitles">
                          {key}
                        </Table.Cell>
                        <Table.Cell rowSpan={items?.length}>{description}</Table.Cell>
                        <Table.Cell>{items[0]?.bathrooms} BA</Table.Cell>
                        <Table.Cell>${items[0]?.price}</Table.Cell>
                      </Table.Row>
                      {items.slice(1).map((item) => (
                        <Table.Row key={index}>
                          <Table.Cell>{item?.bathrooms} BA</Table.Cell>
                          <Table.Cell>${item?.price}</Table.Cell>
                        </Table.Row>
                      ))}
                    </>
                  );
                })}
              </Table.Body>
            </Table>
          </div>

          <p className="availablityText">
            Limited availability. Discount on Amenify days - check in the Resident App
          </p>
        </Animated>
      </div>
    </section>
  );
};

Prices.propTypes = {
  name: PropTypes.string,
  cleaningPrices: PropTypes.object,
};

Prices.defaultProps = {
  name: '',
  cleaningPrices: {},
};

export default Prices;
