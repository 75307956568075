import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/react-hooks';
import client from '@api/client/apollo';
import history from '@utils/history';
import Home from '@screens/home';
import 'animate.css/animate.min.css';
import 'semantic-ui-css/semantic.min.css';

import reportWebVitals from './reportWebVitals';
import './index.scss';

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <Router history={history}>
        <Home />
      </Router>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
