import React, { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import { Button } from '@components';

import { getMenuItems } from '../constants';
import { LINKS } from '@src/constants/externalLinks';

const MobileNav = ({ isOpened, enabledProductCategories }) => {
  const { pathname } = useLocation();
  const [selectedSubItems, setSelectedSubItems] = useState();
  const [subitemsOpened, setSubitemsOpened] = useState(false);

  const handleOpenSubItems = useCallback((items) => {
    setSelectedSubItems(items);
    setSubitemsOpened(true);
  }, []);

  const handleGoBack = useCallback(() => {
    setSubitemsOpened(false);
    setTimeout(() => setSelectedSubItems(undefined), [300]);
  }, []);

  useEffect(() => {
    document.body.style.overflowY = isOpened ? 'hidden' : 'initial';
  }, [isOpened]);

  return (
    <div
      className={`mobile-nav${isOpened ? ' mobile-nav--opened' : ''}${
        subitemsOpened ? ' mobile-nav--subitems' : ''
      }`}>
      <div className="mobile-nav__content">
        <ul className={`mobile-nav__list${subitemsOpened ? ' mobile-nav__list--hidden' : ''}`}>
          {getMenuItems(enabledProductCategories).map(({ label, link, items }) => {
            const isActive = pathname.includes(link) || pathname.includes(label.toLowerCase());

            return (
              <li className="mobile-nav__list-item" key={label}>
                {!!items && (
                  <button
                    onClick={() => handleOpenSubItems(items)}
                    className={`mobile-nav__link${isActive ? ' mobile-nav__link--active' : ''}${
                      items ? ' nav-arrow-right' : ''
                    }`}>
                    {label}
                  </button>
                )}
                {!items && (
                  <a
                    className={`mobile-nav__link ${isActive ? 'mobile-nav__link--active' : ''}`}
                    href={link}>
                    {label}
                  </a>
                )}
              </li>
            );
          })}
          <li className="mobile-nav__list-item">
            <Button href={LINKS.SIGNUP} className="mobile-nav__button button">
              Sign in/Sign up
            </Button>
          </li>
        </ul>
        <ul
          className={`mobile-nav__list mobile-nav__subitems${
            subitemsOpened ? ' mobile-nav__subitems--opened' : ''
          }`}>
          <li className="mobile-nav__list-item">
            <button
              className="mobile-nav__link mobile-nav__link--back nav-arrow-left"
              onClick={handleGoBack}>
              Back
            </button>
          </li>
          {Boolean(selectedSubItems) &&
            selectedSubItems.map(({ label, link }) => (
              <li className="mobile-nav__list-item" key={label}>
                <a className="mobile-nav__link" href={link}>
                  {label}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  enabledProductCategories: PropTypes.object,
};

MobileNav.defaultProps = {
  enabledProductCategories: {},
};

export default MobileNav;
