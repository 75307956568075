import React from 'react';
import { PropTypes } from 'prop-types';

import { Animated } from '@components';

import { getMenuItems } from './constants';

const Footer = ({ enabledProductCategories }) => {
  const meals = enabledProductCategories.meals ? ', food delivery ' : '';

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer section">
      <div className="footer__container container">
        <Animated className="footer__content">
          <h2 className="footer__title text">Amenify</h2>
          <p className="small-text">
            Amenify is a real estate technology company that offers professional cleaning, chores,
            housekeeping, dog walking
            {meals} lifestyle services for multifamily residents.
          </p>
          <div>
            <a
              href="https://www.instagram.com/joinamenify"
              target="_blank"
              rel="noreferrer"
              className="footer__social-link">
              <img src="/instagram-icon.svg" />
            </a>
            <a
              href="https://www.facebook.com/Amenify"
              target="_blank"
              rel="noreferrer"
              className="footer__social-link">
              <img src="/facebook-icon.svg" />
            </a>
          </div>
          <div className="footer__store-links">
            <a
              href="https://tiny.cc/amenify-ios-app"
              target="_blank"
              rel="noreferrer"
              className="footer__store-link">
              <img src="/app-apple.png" />
            </a>
            <a
              href="https://tiny.cc/amenify-android-app"
              target="_blank"
              rel="noreferrer"
              className="footer__store-link">
              <img src="/app-google.png" />
            </a>
          </div>
        </Animated>
        <Animated className="footer__nav">
          {getMenuItems(enabledProductCategories).map(({ title, items }) => (
            <div key={title} className="footer__nav-content">
              <span className="footer__nav-title text">{title}</span>
              <ul className="footer__nav-list">
                {items.map(({ text, link }) => (
                  <li key={text} className="footer__nav-item">
                    <a href={link} className="footer__nav-link">
                      {text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Animated>
      </div>
      <div className="container">
        <span className="footer__copy">
          &copy; {currentYear} Amenify Corporation. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  enabledProductCategories: PropTypes.object,
};

Footer.defaultProps = {
  enabledProductCategories: {},
};

export default Footer;
