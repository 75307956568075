export const getMenuItems = (enabledProductCategories) => {
  const serviceItems = [];
  if (enabledProductCategories.meals) {
    serviceItems.push({
      text: 'Meal Delivery Services',
      link: '#meal-delivery-services',
    });
  }
  if (enabledProductCategories.dogWalking) {
    serviceItems.push({
      text: 'Dog Walking Services',
      link: 'https://www.amenify.com/dog-walking-services',
    });
  }
  if (enabledProductCategories.cleaning) {
    serviceItems.push({
      text: 'Cleaning Services',
      link: '#cleaning-and-chores',
    });
  }
  if (enabledProductCategories.chores) {
    serviceItems.push({
      text: 'Chores Services',
      link: '#cleaning-and-chores',
    });
  }
  if (enabledProductCategories.carWashing) {
    serviceItems.push({
      text: 'Car Washing Services',
      link: '#car-washing-services',
    });
  }

  return [
    ...(serviceItems.length
      ? [
          {
            title: 'Services',
            items: serviceItems,
          },
        ]
      : []),
    {
      title: 'Info',
      items: [
        {
          text: 'About us',
          link: '#overview',
        },
        {
          text: 'Residents',
          link: 'https://www.amenify.com/residents-services',
        },
        {
          text: 'Managers',
          link: 'https://www.amenify.com/managers',
        },
        {
          text: 'Providers',
          link: 'https://www.amenify.com/providers',
        },
        {
          text: 'Drivers',
          link: 'https://www.amenify.com/drivers',
        },
        {
          text: 'Resident Ambassador',
          link: 'https://www.amenify.com/resident-ambassador',
        },
        {
          text: 'How it works',
          link: 'https://www.amenify.com/how-it-works',
        },
        {
          text: 'Find your Community',
          link: 'https://www.amenify.com/find-your-community?page_source=home',
        },
      ],
    },
    {
      title: 'Quick Links',
      items: [
        {
          text: 'Create a Resident Account',
          link: 'https://m.amenify.com/sign-up/?utm_campaign=home_page&amp;utm_medium=direct&amp;utm_source=home_page?page_source=landing-test',
        },
        {
          text: 'Blog',
          link: 'https://www.amenify.com/blog/?page_source=home',
        },
        {
          text: 'Contact us',
          link: 'https://www.amenify.com/contact-us?page_source=home',
        },
        {
          text: 'Resident Log In',
          link: 'https://m.amenify.com/sign-in/?utm_campaign=home_page&amp;utm_medium=direct&amp;utm_source=home_page?page_source=landing-test',
        },
        {
          text: 'Service Pro Log In',
          link: 'https://field-tech.amenify.com?page_source=landing-test',
        },
        {
          text: 'Provider log in',
          link: 'https://provider.amenify.com?page_source=landing-test',
        },
        {
          text: 'Mission and Values',
          link: 'https://provider.amenify.com/mission-and-values',
        },
        {
          text: 'Terms and Privacy',
          link: 'https://provider.amenify.com/terms-and-privacy',
        },
        {
          text: 'COVID-19 Policy',
          link: 'https://www.amenify.com/updatedprotocols?page_source=home',
        },
        {
          text: 'Sitemap',
          link: 'https://www.amenify.com/sitemap?page_source=landing-test',
        },
      ],
    },
  ];
};
