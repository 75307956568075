import React from 'react';
import { HandymanImage } from '@assets';
import './Handyman.scss';
import { Animated } from '@components';
import { LINKS } from '@src/constants/externalLinks';
import { Icon } from 'semantic-ui-react';

const HANDYMAN_POINTS = [
  'Electrical, plumbing, painting, carpentry or any other home repair task',
  'Trained, and vetted local handyman at your doorstep',
  'Quality assurance by Amenify',
];

const Handyman = () => {
  return (
    <section className="handyman section" id="handyman">
      <div className="container">
        <Animated animateIn="fadeInLeft">
          <div className="handyman__upper-section">
            <div className="handyman__upper-section__text">
              <div className="handyman__upper-section__text__tags">
                <div className="handyman__upper-section__text__tags__secondary">NEW</div>
              </div>
              <h3 className="handyman__upper-section__text__title">Handyman Services</h3>
              <p className="handyman__upper-section__text__description">
                Home maintenance at your fingertips
              </p>

              <div className="handyman__upper-section__text__points">
                {HANDYMAN_POINTS.map((point, index) => (
                  <div key={index} className="handyman__upper-section__text__points__point">
                    <Icon name="circle" size="mini" />
                    <p className="pointText">{point}</p>
                  </div>
                ))}
              </div>

              <div className="handyman__upper-section__text__buttons">
                <a
                  className="handyman__upper-section__text__buttons__button primary"
                  href={LINKS.SIGNIN}
                  rel="noopener noreferrer"
                  target="_blank">
                  Order Now
                </a>
                <a
                  className="handyman__upper-section__text__buttons__button secondary"
                  href={LINKS.SIGNUP}
                  rel="noopener noreferrer"
                  target="_blank">
                  Create an account
                </a>
              </div>
            </div>

            <div className="handyman__upper-section__image">
              <img src={HandymanImage} alt="handyman-services" />
            </div>
            <div className="handyman__upper-section__text__buttons mobile">
              <a
                className="handyman__upper-section__text__buttons__button primary"
                href={LINKS.SIGNIN}
                rel="noopener noreferrer"
                target="_blank">
                Order Now
              </a>
              <a
                className="handyman__upper-section__text__buttons__button secondary"
                href={LINKS.SIGNUP}
                rel="noopener noreferrer"
                target="_blank">
                Create an account
              </a>
            </div>
          </div>
        </Animated>
      </div>
    </section>
  );
};

export default Handyman;
