import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { PropTypes } from 'prop-types';

const Animated = ({ children, animateIn, animateOut, className, ...props }) => (
  <ScrollAnimation
    {...props}
    animateOnce
    className={`animate__animated ${className ?? ''}`}
    animateIn={animateIn && `animate__${animateIn}`}
    animateOut={animateOut && `animate__${animateOut}`}>
    {children}
  </ScrollAnimation>
);

Animated.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  className: PropTypes.string,
  animateIn: PropTypes.string,
  animateOut: PropTypes.string,
};

Animated.defaultProps = {
  className: '',
  animateIn: 'fadeIn',
  animateOut: '',
};

export default Animated;
