import React, { useState, useCallback } from 'react';

import { Accordion, Animated } from '@components';

const DeliveryFeatures = () => {
  const [openedAccordionIndex, setOpenedAccordionIndex] = useState();

  const handleClickAccordion = useCallback(
    (index) =>
      setOpenedAccordionIndex(typeof openedAccordionIndex === 'undefined' ? index : undefined),
    [openedAccordionIndex],
  );

  return (
    <section className="delivery-features section">
      <div className="delivery-features__container container">
        <div className="delivery-features__info-wrapper">
          <Animated>
            <h2 className="delivery-features__title title">
              Exclusive Meal Delivery
              <br />
              Features for Residents
            </h2>
          </Animated>
          <Animated>
            <p className="delivery-features__text text">
              Meals are prepared fresh in our partner kitchens and delivered right to your
              residence. Amenify powered communities get special pricing and access to limited menu
              options so that they can enjoy delicious and hassle-free meals.
            </p>
          </Animated>
        </div>
        <Animated className="delivery-features__accordions">
          <Accordion
            opened={openedAccordionIndex === 0}
            onClick={() => handleClickAccordion(0)}
            title="Made Fresh Daily"
            text="The private menu is curated by Amenify, based on your feedback, and all meals are made day-of by local, professional chefs."
          />
          <Accordion
            opened={openedAccordionIndex === 1}
            onClick={() => handleClickAccordion(1)}
            title="Stop Paying for Delivery"
            text="Other platforms embed $8-12 in fees that don’t make sense -- but not us. We have lower prices and better quality."
          />
          <Accordion
            opened={openedAccordionIndex === 2}
            onClick={() => handleClickAccordion(2)}
            title="Schedule your Meals"
            text="All orders are delivered to a specific location in your building, for a safe and secure pick-up."
          />
        </Animated>
      </div>
    </section>
  );
};

export default DeliveryFeatures;
