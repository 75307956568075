import { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import UrgeCards from '@api/queries/UrgeCards.graphql';

const useUrgeCards = () => {
  const [getUrgeCards, { loading, error = null, data }] = useLazyQuery(UrgeCards);

  useEffect(() => {
    const pattern = new UrlPattern('(/:landingSlug)');
    window.UrlPattern = UrlPattern;
    const path = document.location.pathname;
    const parsed = pattern.match(path);
    const lastLink = path.slice(path.lastIndexOf('/') + 1);

    if (!parsed && lastLink === 'property-landing') {
      return;
    }
    const landingSlug = parsed?.landingSlug || lastLink;

    if (landingSlug) {
      getUrgeCards({ variables: { landingSlug } });
    }
  }, [getUrgeCards]);

  const urgeCards = useMemo(() => data?.urgeCards || [], [data]);

  return { loading, error, urgeCards };
};

export default useUrgeCards;
